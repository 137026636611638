<template>
  <div class="publishVideo" @click.prevent>
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">发布视频</div>
      <div class="rightBtn" @click="$router.push('/uploadRules')">发布规则</div>
      <!--            <div class="rightBtn" @click="publish">发布</div>-->
    </div>
    <div class="main">
      <!-- 选择话题 -->
      <div
        class="topicBox"
        v-if="!(tags.length > 0)"
        @click="$router.push('/selectTag')"
      >
        <div class="topicLeft">
          <svg-icon icon-class="#"></svg-icon>
          <div>选择话题</div>
        </div>
        <div class="topicRight">
          <div>请遵守社区规则</div>
          <svg-icon icon-class="rightGray"></svg-icon>
        </div>
      </div>
      <div class="topicBox" v-else @click="$router.push('/selectTag')">
        <div class="topicLeft">
          <ImgDecypt
            class="avatarImg"
            v-if="showTag"
            :src="tags[0].coverImg"
            :key="tags[0].coverImg"
          />
          <div class="tagInfo">
            <div class="name">{{ tags[0].name }}</div>
            <div class="desc">{{ tags[0].tagDesc }}</div>
          </div>
        </div>
        <div class="topicRight">
          <svg-icon icon-class="rightGray"></svg-icon>
        </div>
      </div>
      <van-field
        class="newInput"
        v-model="title"
        autosize
        placeholder="请填写标题"
      />
      <van-field
        class="newInput"
        v-model="content"
        rows="6"
        autosize
        type="textarea"
        maxlength="555"
        placeholder="详细描述下吧～留下你的文采..."
        show-word-limit
      />
      <div class="publishTitle">添加视频</div>
      <div class="up_video_box" @click.stop>
        <video
          class="previewVideo"
          id="video"
          style="object-fit: cover"
          v-show="false"
        ></video>
        <div class="update-box">
          <van-uploader
            accept="video/*"
            :max-count="1"
            :after-read="afterVideo"
            v-if="!startUploadVideo"
            :before-read="beforeRead"
          >
            <div class="upload-icon">
              <i></i>
              <svg-icon class="uploadAdd" icon-class="upload_add" />
              <span class="tip">添加视频</span>
            </div>
          </van-uploader>

          <div v-if="startUploadVideo" class="uploading">
            <div class="video-mask"></div>
            <van-circle
              v-model="currentRate"
              :rate="uploadStatue.percent"
              :speed="100"
              color="#00b95b"
              :text="currentRate + '%'"
            />
          </div>
          <div
            class="video_close"
            @click="uploadDelete"
            v-if="
              uploadStatue.statusText == '上传成功' ||
              uploadStatue.statusText == '上传失败'
            "
          >
            <svg-icon icon-class="close2" />
          </div>
        </div>
        <div
          v-if="startUploadVideo"
          :class="[
            'info_video',
            uploadStatue.statusText == '上传成功' ? 'green' : 'F5164E',
          ]"
        >
          {{ uploadStatue.statusText }}
        </div>
      </div>
      <div class="publishTitle">添加图集</div>
      <div class="uploaderBox" @click.stop>
        <UploaderFile
          @uploadSuccess="uploadSuccess"
          @delImg="delImg"
          ref="UploadFile"
          :multiple="true"
        >
        </UploaderFile>
      </div>

      <!-- 关联视频 -->
      <!--            <div class="correlationVideo">-->
      <!--                <div class="topicLeft">-->
      <!--                    <svg-icon icon-class="correlationIcon"></svg-icon>-->
      <!--                    <div>关联视频</div>-->
      <!--                </div>-->
      <!--                <div class="topicRight">-->
      <!--                    <div>喜欢中选择</div>-->
      <!--                    <svg-icon icon-class="rightGray"></svg-icon>-->
      <!--                </div>-->
      <!--            </div>-->

      <!-- 设置价格 -->
      <div class="settingPrice" @click="setPrice">
        <div class="topicLeft">
<!--          <svg-icon icon-class="gold2Icon"></svg-icon>-->
          <div class="gold2Icon"></div>
          <div>设置价格</div>
        </div>
        <div class="topicRight">
          <div :class="price > 0 ? 'price' : ''">
            {{ price > 0 ? price + "金币" : "免费" }}
          </div>
          <svg-icon icon-class="rightGray"></svg-icon>
        </div>
      </div>

      <!-- 发布 -->
      <div class="btnGroup" @click="publish">
        <div class="btn">确认发布</div>
      </div>

      <!--            <div class="uploaderBox">-->
      <!--                <div class="upImgBox" @click.stop>-->
      <!--                    <div class="subTitle">上传封面</div>-->
      <!--                    <UploaderFile @uploadSuccess="uploadSuccess" @delImg="delImg" ref="UploadFile" maxCount="1" />-->
      <!--                </div>-->
      <!--&lt;!&ndash;                <div class="upVideoBox">&ndash;&gt;-->
      <!--&lt;!&ndash;                    <div class="subTitle">上传视频</div>&ndash;&gt;-->
      <!--&lt;!&ndash;                    &lt;!&ndash;  上传视频&ndash;&gt;&ndash;&gt;-->
      <!--&lt;!&ndash;                    <div class="up_video_box" @click.stop>&ndash;&gt;-->
      <!--&lt;!&ndash;                        <video class="previewVideo" id="video" style="object-fit:cover" v-show="false"></video>&ndash;&gt;-->
      <!--&lt;!&ndash;                        <div class="update-box">&ndash;&gt;-->
      <!--&lt;!&ndash;                            <van-uploader accept="video/*" :max-count="1" :after-read="afterVideo"&ndash;&gt;-->
      <!--&lt;!&ndash;                                v-if="!startUploadVideo" :before-read="beforeRead">&ndash;&gt;-->
      <!--&lt;!&ndash;                                <div class="upload-icon">&ndash;&gt;-->
      <!--&lt;!&ndash;                                    <i></i>&ndash;&gt;-->
      <!--&lt;!&ndash;                                    <svg-icon class="uploadAdd" icon-class="upload_add" />&ndash;&gt;-->
      <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
      <!--&lt;!&ndash;                            </van-uploader>&ndash;&gt;-->
      <!--&lt;!&ndash;                            <div v-if="startUploadVideo" class="uploading">&ndash;&gt;-->
      <!--&lt;!&ndash;                                <div class="video-mask"></div>&ndash;&gt;-->
      <!--&lt;!&ndash;                                <van-circle v-model="currentRate" :rate="uploadStatue.percent" :speed="100"&ndash;&gt;-->
      <!--&lt;!&ndash;                                    color="#00b95b" :text="currentRate + '%'" />&ndash;&gt;-->
      <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
      <!--&lt;!&ndash;                            <div class="video_close" @click="uploadDelete"&ndash;&gt;-->
      <!--&lt;!&ndash;                                v-if="uploadStatue.statusText == '上传成功' || uploadStatue.statusText == '上传失败'">&ndash;&gt;-->
      <!--&lt;!&ndash;                                <svg-icon icon-class="close2" />&ndash;&gt;-->
      <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
      <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
      <!--&lt;!&ndash;                        <div v-if="startUploadVideo"&ndash;&gt;-->
      <!--&lt;!&ndash;                            :class="['info_video', uploadStatue.statusText == '上传成功' ? 'green' : 'F5164E']">&ndash;&gt;-->
      <!--&lt;!&ndash;                            {{ uploadStatue.statusText }}</div>&ndash;&gt;-->
      <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
      <!--&lt;!&ndash;                    &lt;!&ndash;  上传视频&ndash;&gt;&ndash;&gt;-->
      <!--&lt;!&ndash;                </div>&ndash;&gt;-->

      <!--            </div>-->
      <!--            <div class="pubilshTitle mt18">选择话题</div>-->
      <!--            <div class="tagList">-->
      <!--                <div class="tagItem" v-for="(item) in tags" :key="item.id">-->
      <!--                    <div class="tagCloseBtn" @click="delTag(item.name)">-->
      <!--                        <svg-icon class="tagClose" iconClass="tagClose" />-->
      <!--                    </div>-->
      <!--                    #{{ item.name }}-->
      <!--                </div>-->
      <!--                <router-link tag="div" to="/selectTag" class="addTag">-->
      <!--                    <svg-icon class="addTagIcon" iconClass="addTag" />-->
      <!--                </router-link>-->
      <!--            </div>-->
      <!--            <div class="pubilshTitle">设置价格</div>-->
      <!--            <div class="priceBox">-->
      <!--                <svg-icon class="priceIcon" iconClass="gold" />-->
      <!--                <div class="price" @click="setPrice">{{price}}</div>-->
      <!--            </div>-->
    </div>
    <PostAmountPop ref="postAmountPop" @setAmount="setAmount" />
  </div>
</template>

<script>
import { Field, Toast, Circle, Uploader } from "vant";
import { mapGetters } from "vuex";
import { upLoadVideo } from "@/api/app";
import UploaderFile from "@/components/UploaderFile";
import PostAmountPop from "@/components/PostAmountPop";
import SparkMD5 from "spark-md5";
import ImgDecypt from "@/components/ImgDecypt";
import { releaseVidSubmit } from "@/api/community";
const fileReaderPromisify = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    let Spark = new SparkMD5.ArrayBuffer();
    reader.readAsArrayBuffer(file);
    reader.onload = function (e) {
      Spark.append(e.target.result);
      let md5 = Spark.end();
      resolve(md5);
    };
    reader.onerror = (err) => {
      reject(err);
    };
  });
};
export default {
  name: "publishVideo",
  components: {
    UploaderFile,
    PostAmountPop,
    ImgDecypt,
    [Field.name]: Field,
    [Circle.name]: Circle,
    [Uploader.name]: Uploader,
  },
  computed: {
    ...mapGetters({
      tags: "tags",
    }),
  },
  watch: {
    $route(to, from) {
      if (from.path === "/selectTag" && this.tags.length) {
        this.showTag = true;
      } else {
        this.showTag = false;
      }
    },
  },
  data() {
    return {
      showTag: false,
      placeholderText: "请输入标题（至少三个字）～ \n最多字是255个字",
      title: "",
      content: "",
      maxSize: 1024 * 1024 * 300, //最大上传视频
      isRewardShow: false, //是否打开大赏弹窗
      amountMatrix: [1, 5, 10, 20, 50],
      customizeMoney: "", //手动输入金额
      curMoneyIndex: -1,
      price: 0, //设置的金额
      imgList: [],
      isLoadingShow: false,
      startUploadVideo: false, //视频是否上传中
      chunk_size: 1024 * 1024 * 2, //分片大小
      retry_request: 5, //重试 加 失败
      currentRate: 0,
      uploadStatue: {
        statusText: "上传中,请勿退出",
        percent: 0, //当前上传文件执行的百分比
      },
      totalPos: -1, // 视频分片总数
      sendVideoSucNum: 0, //分片上传视频，成功收到的返回
      totalProgress: {}, //总进度
      videoPreviewSrc: "", //封面预览
      isImgUoloadErr: false, //封面上传是否失败
      requestAll: [], //总请求
      requestNum: 0, //正在请求的数量
      requestMaxNum: 6, //同时最大请求数量
      uploadData: {
        title: "", //标题
        content: "",
        newsType: "SP", //上传类型 COVER-图集 SP-视频
        tags: [], //视频标签数组
        playTime: 0, //视频时长
        cover: "", //封面
        coverThumb: "", //封面小图
        seriesCover: [], // 图集图片地址
        via: "", //视频来源
        coins: 0, //观看金币
        size: 0, //文件大小
        mimeType: "", //文件类型
        actor: "", //演员名字
        sourceURL: "", //资源url 为SP时 必传
        sourceID: "", //上��视频成功后 返回���ID 为SP时必传
        filename: "", //文件名
        resolution: "", //分辨率
        ratio: 1, //视频比例
        md5: "", //文件摘要
        freeTime: 0, //免费观影时长
        location: {
          city: "",
        }, //地理位置信息
      },
    };
  },
  methods: {
    // 返回
    backPage() {
      this.$store.commit("commnity/CLEARALL_TAGS");
      this.$router.go(-1);
    },
    uploadSuccess(img) {
      this.imgList.push(img);
      // this.uploadData.cover = img
    },
    delImg(index) {
      this.imgList.splice(index, 1);
      // if (this.imgList.length <= 5) {
      //     this.price = 0;
      // }
      // this.uploadData.cover = "";
    },
    //删除标签
    delTag(name) {
      this.$store.commit("commnity/DELITEM_TAG", name);
    },
    async publish() {
      this.uploadData.title = this.title;
      this.uploadData.content = this.content;
      this.uploadData.tags = this.tags.map((e) => e.id);
      this.uploadData.location.city = this.location;
      this.uploadData.coins = parseInt(this.price);
      if (this.$route.query.tId) {
        this.uploadData.taskID = this.$route.query.tId;
      }

      if (this.uploadData.title.length == 0) {
        Toast("请输入标题");
        return;
      }
      if (!this.uploadData.sourceURL) {
        Toast("请上传视频或等视频上传完毕");
        return;
      }
      if (this.imgList.length > 0) {
        this.uploadData.cover = this.imgList[0];
        this.uploadData.seriesCover = this.imgList;
      }
      // if (!this.uploadData.cover) {
      //     Toast("请上传封面");
      //     return;
      // }

      if (this.uploadData.location.city == "选择地址") {
        Toast("请选择地址");
        return;
      }
      if (this.uploadData.coins > 10000) {
        Toast("作品金额最高只能设置10000金币");
        return;
      }
      if (this.uploadData.tags.length == 0) {
        Toast("请选择标签");
        return;
      }
      if (this.uploadData.playTime < 30) {
        Toast("视频时长不能小于30秒");
        return;
      }
      if (
        this.uploadStatue.statusText != "上传成功" &&
        this.uploadStatue.statusText != "上传失败"
      ) {
        Toast("请等待视频上传完成");
        return;
      }
      if (this.uploadStatue.statusText == "上传失败") {
        Toast("视频上传失败，请重新上传");
        return;
      }
      try {
        this.$store.commit("app/SET_LOADING", true);
        let res = await this.$Api(releaseVidSubmit, this.uploadData);
        this.$store.commit("app/SET_LOADING", false);
        if (res.code == 200) {
          this.restData();
          this.uploadDelete();
          this.$store.commit("commnity/CLEARALL_TAGS");
          this.$router.push("/creationCenter");
          Toast("发布成功");
          return;
        }
        this.promptShow = true;
        // Toast(res.tip || "发布失败");
      } catch (e) {
        this.$store.commit("app/SET_LOADING", false);
      }
    },
    setPrice() {
      this.$refs.postAmountPop.showPop();
    },
    setAmount(amount) {
      this.price = amount;
    },

    //上传视频
    beforeRead(file) {
      if (file.size > this.maxSize) {
        Toast("视频最大为300M");
        return false;
      }
      return true;
    },
    async afterVideo(e) {
      this.startUploadVideo = true;
      let file = e.file;
      let fileMd5 = await fileReaderPromisify(file);
      let file_chunks = this.getFileChunks(file);
      let length = file_chunks.length;
      this.totalPos = length;
      for (let index in file_chunks) {
        index = Number(index);
        let chunk = file_chunks[index];
        let opts = {
          pos: index + 1,
          totalPos: length,
          data: Buffer.from(await chunk.arrayBuffer(), "utf8").toString(
            "base64"
          ),
          id: fileMd5,
        };
        this.uploadData.md5 = fileMd5;
        this.requestAll.push(opts);
      }
      this.getVideoPlayerInfo(file); //获取视频宽高
      this.requestConcurrency();
    },
    //限制请求数量
    requestConcurrency() {
      while (
        this.requestAll.length > 0 &&
        this.requestNum <= this.requestMaxNum &&
        this.requestNum >= 0
      ) {
        let opts = this.requestAll.shift();
        this.upload(opts);
      }
    },
    // 开始上传
    async upload(options) {
      try {
        this.requestNum++;
        //封面上传失败
        if (this.isImgUoloadErr) {
          this.uploadStatue.statusText = "上传失败";
          return;
        }
        let callback = (processCallback) => {
          // console.log(processCallback.loaded , "---")
          // console.log(processCallback.total , "+++")
          let percent = parseInt(
            (processCallback.loaded / processCallback.total) * 100
          );
          this.totalProgress[options.pos - 1] = percent.toFixed(2);
          let strArr = Object.values(this.totalProgress);
          let numberArr = strArr.map(Number);
          this.uploadStatue.percent = this.average(numberArr).toFixed(1);
          // this.uploadStatue.percent = parseInt(this.average(numberArr));
          // console.log(this.uploadStatue.percent)
        };
        let ret = await this.$Api(upLoadVideo, options, undefined, callback);
        if (ret.code == 200) {
          this.sendVideoSucNum++;
          // 分片上传成功的次数
        }
        if (
          ret.code == 200 &&
          this.uploadStatue.percent == 100 &&
          this.sendVideoSucNum == options.totalPos
        ) {
          this.uploadStatue.statusText = "上传成功";
          this.uploadData.sourceURL = ret.data.videoUri;
          this.uploadData.sourceID = ret.data.id;
          return;
        }
        if (ret.code != 200) {
          //暂时这样，空了优化
          this.retry_request--;
          if (this.retry_request >= 0) {
            this.requestAll.push(options);
          }
          return;
        }
      } catch (e) {
        this.retry_request--;
      } finally {
        this.requestNum--;
        if (this.retry_request >= 0) {
          this.requestConcurrency();
        } else {
          this.currentRate = 0;
          this.uploadStatue.percent = 0;
          this.uploadStatue.statusText = "上传失败";
          this.sendVideoSucNum = 0;
        }
      }
    },
    // 将视频分片
    getFileChunks(file) {
      let { size } = file;
      let total_chunks = Math.ceil(size / this.chunk_size);
      let file_chunks = [];
      if (size > this.chunk_size) {
        for (let i = 0; i < total_chunks; i++) {
          let start = i * this.chunk_size;
          let end = (i + 1) * this.chunk_size;
          let chunk = file.slice(start, end);
          this.totalProgress[i] = 0;
          file_chunks.push(chunk);
        }
      } else {
        file_chunks.push(file);
        this.totalProgress[0] = 0;
      }
      return file_chunks;
    },
    base64toFile(baseUrl, filename = "file") {
      let arr = baseUrl.split(",");
      let type = arr[0].match(/:(.*?);/)[1]; // 解锁图片类型
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let bufferArray = new Uint8Array(n);
      while (n--) {
        bufferArray[n] = bytes.charCodeAt(n);
      }
      return new File([bufferArray], filename, {
        type,
      });
    },
    // 删除视频
    uploadDelete() {
      this.uploadData.sourceURL = "";
      this.uploadStatue.statusText = "上传中,请勿退出";
      this.uploadStatue.percent = 0;
      this.currentRate = 0;
      this.sendVideoSucNum = 0;
      this.startUploadVideo = false;
    },
    // 获取视频信息
    getVideoPlayerInfo(file) {
      this.uploadData.size = file.size;
      this.uploadData.filename = file.name;
      this.uploadData.mimeType = file.type;
      this.$nextTick(() => {
        const videoElement = document.getElementById("video");
        videoElement.src = URL.createObjectURL(file);
        videoElement.addEventListener("loadedmetadata", () => {
          this.uploadData.playTime = parseInt(videoElement.duration);
          this.uploadData.ratio = Number(
            (videoElement.videoWidth / videoElement.videoHeight).toFixed(2)
          );
          this.uploadData.resolution =
            videoElement.videoWidth + "x" + videoElement.videoHeight;
        });
      });
    },
    // 平均数
    average(nums) {
      return nums.reduce((a, b) => a + b) / nums.length;
    },
    restData() {
      this.uploadData = {
        title: "", //标题
        newsType: "SP", //上传类型 COVER-图集 SP-视频
        tags: [], //视频标签数组
        playTime: 0, //视频时长
        cover: "", //封面
        coverThumb: "", //封面小图
        seriesCover: [], // 图集图片地址
        via: "", //视频来源
        coins: 0, //观看金币
        size: 0, //文件大小
        mimeType: "", //文件类型
        actor: "", //演员名字
        sourceURL: "", //资源url 为SP时 必传
        sourceID: "", //上��视频成功后 返回���ID 为SP时必传
        filename: "", //文件名
        resolution: "", //分辨率
        ratio: 1, //视频比例
        md5: "", //文件摘要
        freeTime: 0, //免费观影时长
        location: {
          city: "",
        }, //地理位置信息
      };
      this.uploadData.title = "";
      this.uploadData.coins = 0;
      this.title = "";
      this.price = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.publishVideo {
  height: 100vh;
  color: #000;
  overflow: hidden;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #333333;
      padding-left: 35px;
    }

    .rightBtn {
      font-size: 14px;
      color: #666666;
      // width: 17px;
      // height: 17px;
    }
  }

  .main {
    height: calc(100% - 45px);
    overflow-y: auto;
    //background: #f5f5f5;
    padding: 18px 16px;

    .topicBox {
      height: 50px;
      border-radius: 8px;
      background: #F9F9F9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #666666;
      padding: 0 12px;

      .topicLeft {
        display: flex;
        //align-items: center;

        .avatarImg {
          width: 38px;
          height: 38px;
          border-radius: 4px;

          /deep/ .van-image__img {
            border-radius: 4px;
          }
        }

        .tagInfo {
          margin-left: 8px;

          .name {
            color: #333;
            font-size: 14px;
            font-weight: 500;
          }

          .desc {
            color: #666;
            font-size: 12px;
            font-weight: 400;
          }
        }

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }

      .topicRight {
        display: flex;
        align-items: center;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }
      }
    }

    .correlationVideo {
      height: 50px;
      border-radius: 8px;
      background: #ebebeb;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #666666;
      padding: 0 12px;
      margin-top: 18px;

      .topicLeft {
        display: flex;
        //align-items: center;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }

      .topicRight {
        display: flex;
        align-items: center;
        color: #999;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }
      }
    }

    .settingPrice {
      height: 50px;
      border-radius: 8px;
      background: #FAFAFA;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #666666;
      padding: 0 12px 0 8px;
      margin-top: 18px;

      .topicLeft {
        display: flex;
        align-items: center;

        .gold2Icon{
          width: 24px;
          height: 24px;
          background-image: url("../../../assets/png/gold2Icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 4px;
          margin-top: 5px;
        }
      }

      .topicRight {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }

        .price {
          color: #FBD13D;
        }
      }
    }

    .btnGroup {
      display: flex;
      justify-content: center;
      margin: 91px 0;

      .btn {
        width: 164px;
        height: 46px;
        border-radius: 22px;
        background: #FBD13D;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #424242;
      }
    }
  }

  .newInput {
    margin-top: 11px;
    // padding: 0 0 12px 0;
    box-sizing: border-box;
    margin-bottom: 15px;
    background: #F9F9F9;
    border-radius: 5px;
    // border-bottom: 1px solid #282544;

    ::placeholder {
      font-size: 12px;
      color: #999999;
    }

    /deep/ .van-field__control {
      font-size: 12px;
      color: #000;
    }

    /deep/ .van-field__word-limit {
      font-size: 15px;
      color: #999999;
    }

    .clearBtn {
      margin-right: 12px;
      width: 22px;
      height: 22px;

      .inputClose {
        width: 100%;
        height: 100%;
      }
    }
  }

  .pubilshTitle {
    font-size: 18px;
    font-weight: 600;
    padding: 0 12px;
    box-sizing: border-box;
  }

  .subTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .priceBox {
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .priceIcon {
      margin: 12px 9px 0 0;
      width: 43px;
      height: 35px;
    }

    .price {
      width: 55px;
      height: 21px;
      border-radius: 6px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e6e6e6;
    }
  }

  .up_video_box {
    margin-top: 12px;
    margin-bottom: 18px;
  }

  .uploaderBox {
    margin-top: 12px;
    //padding: 0 12px;
    box-sizing: border-box;
    display: flex;

    .upImgBox {
      margin-right: 18px;
    }
  }

  .tagList {
    display: flex;
    font-size: 12px;
    color: #000;
    margin-top: 11px;
    flex-wrap: wrap;
    padding: 0 12px;
    box-sizing: border-box;

    .tagItem {
      position: relative;
      margin-right: 18px;
      margin-bottom: 18px;
      padding: 2px 6px;
      border-radius: 6px;
      background: #e6e6e6;

      .tagCloseBtn {
        position: absolute;
        top: -7px;
        right: -7px;

        .tagClose {
          width: 12px;
          height: 12px;
        }
      }
    }

    .addTag {
      margin-bottom: 18px;

      .addTagIcon {
        width: 21px;
        height: 21px;
      }
    }
  }

  .update-box {
    width: 111px;
    height: 111px;
    box-sizing: border-box;
    //background: #ebebeb;
    color: #939bac;
    border: 1px dashed #999;
    // border: 2px solid #3b4454;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .upload-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .tip {
        color: #999;
        font-size: 12px;
        margin-top: 9px;
      }
    }

    .uploading {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      .video-mask {
        width: 126px;
        height: 126px;
        position: absolute;
        top: 0;
        background: #e6e6e6;
      }

      /deep/ .van-circle__text {
        color: #000;
      }

      img {
        width: 100%;
        position: absolute;
      }
    }

    .video_close {
      position: absolute;
      top: -12px;
      right: -7px;
    }

    .van-uploader {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      overflow: hidden;

      /deep/ .van-uploader__wrapper {
        width: 100%;
        height: 100%;
        //background: #e6e6e6;
      }

      /deep/ .van-uploader__input-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      // /deep/ .van-uploader__input {

      // }
    }

    svg {
      font-size: 25px;
    }

    .uploadAdd {
      width: 33px;
      height: 33px;
    }
  }

  .info_video {
    text-align: center;
  }

  .mt18 {
    margin-top: 18px;
  }
}
</style>
